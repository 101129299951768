import styled, {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Open-Sans, Helvetica, Sans-Serif;
    background: rgb(131,58,180);
    background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
  }
`;

const Banner = styled.header`
  padding-top: 100px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 5em;
`;

const App = () => {
  return (
    <>
      <GlobalStyle/>
      <Banner>
        <Title>
          COVID Chart Quiz
        </Title>
        <p>
          -- COMING SOON --
        </p>
      </Banner>
    </>
  );
}

export default App;
